import "bootstrap/dist/css/bootstrap.min.css";
import * as React from "react";
import { useState, useEffect } from "react";
import "../styles/style.scss";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import { MDXRenderer } from "gatsby-plugin-mdx";

// components
import Navbar from "../components/common/Navbar";
import BenefitListItem from "../components/benefit_list_item";
import InnerHeroBanner from "../components/inner-hero-banner";
import Footer from "../components/common/Footer";

// images
//favicon
import favicon from "../images/favicon.ico";
import aboutAviation from "../images/about-aviation.jpg";
import aboutBannerImage from "../images/about-banner.jpg";

import { isEmpty } from "../utils/utils";

// markup
const IndexPage = () => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  const data = useStaticQuery(graphql`
    {
      about: allFile(filter: { absolutePath: { regex: "/about.mdx/" } }) {
        nodes {
          childMdx {
            frontmatter {
              bannerTitle
              bannerSubTitle
              imageUrl
            }
            body
          }
        }
      }
      visionMission: allFile(
        filter: { absolutePath: { regex: "/vision-mission.mdx/" } }
      ) {
        nodes {
          childMdx {
            frontmatter {
              title
            }
            body
          }
        }
      }
    }
  `);

  const about = data?.about?.nodes?.[0]?.childMdx;
  const visionMission = data?.visionMission?.nodes?.[0]?.childMdx;

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      <main>
        <title>NuFlights-About</title>
        <div className="inner-hero">
          <Navbar offset={offset} />
          <InnerHeroBanner
            bannerImage={aboutBannerImage}
            heading={about?.frontmatter?.bannerTitle}
            subHeading={about?.frontmatter?.bannerSubTitle}
          />
        </div>

        {/*JOIN*/}
        <div className="inner-section">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <p className="sub-text">Our Journey</p>
                <h2>We are NuFlights</h2>
                <div className="content-wrapper">
                  {/* {console.log("data", data)} */}
                  <MDXRenderer>{about?.body}</MDXRenderer>
                </div>
              </div>
              <div className="col-md-7 text-center">
                <img src={aboutAviation} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/*JOIN ENDS*/}

        {/*MISSION AND VISION*/}
        <div className="benefits career-benefits-section">
          <div className="container">
            <p className="sub-text">Our Statement </p>
            <h1>{visionMission?.frontmatter?.title}</h1>
            <div className="content-wrapper">
              <MDXRenderer>{visionMission?.body}</MDXRenderer>
            </div>
          </div>
        </div>
        {/*MISSION AND VISION ENDS*/}

        {/*CONTACT BANNER*/}
        <div className="inner-light-blue-bg">
          <div className="container">
            <div className="call-to-action-content text-center">
              <h1>Want to join us?</h1>
              <h3 className="mt-3">
                We would love to hear from you, if you are a travel agency or travel service partner.<br/>
                To join us as an associate, see <a href="/careers">careers</a>.  
              </h3>
              <div className="action mt-4">
                <Link to="/#contact" className="btn btn-primary btn-lg mt-1">
                  Contact Sales
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*CONTACT BANNER ENDS*/}

        <Footer />
      </main>
    </>
  );
};

export default IndexPage;
